import Icon from "@mui/icons-material/DoDisturb";
import { useNotify, useRecordContext, useRefresh } from "react-admin";
import { ActionMenuItem } from "../../../layout/actions-menu";
import { useAxiosMutation } from "../../../providers/data-hooks/useAxiosQuery";
import type { BalancingBid } from "../types";

export const CancelBalancingBidAction = () => {
  const record = useRecordContext<BalancingBid>();
  const refresh = useRefresh();
  const notify = useNotify();

  const { mutate } = useAxiosMutation(
    {
      url: `/balancing-bids/cancel/${record?.id}`,
      method: "PUT",
    },
    {
      onSuccess: () => {
        notify("Balancing bid cancelled", { type: "success" });
        refresh();
      },
      onError: (error) => {
        notify(`Failed to cancel balancing bid: ${error}`, { type: "error" });
      },
    },
  );

  return (
    <ActionMenuItem
      onClick={() => {
        void mutate();
      }}
      label="Cancel"
      icon={<Icon />}
    />
  );
};
