import type { ContractType } from "../devices/types";

export enum DemandResponseEntityMechanism {
  DEMAND_RESPONSE = "DEMAND_RESPONSE",
  BALANCING = "BALANCING",
}

export interface DemandResponseEntity {
  id: string;
  type: "Profilé" | "Télérelevé";
  name: string;
  comment?: string;
  maxCapacityInMW: number;
  method: "RDRC" | "RA";
  isTest: boolean;
  pdls: string[];
  contractType: ContractType;
  mechanism: DemandResponseEntityMechanism;
}
